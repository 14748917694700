.wrapper > h1 {
	text-align: center;
  
	span {
	  display: inline-block;
	  padding: 0 10px 10px;
	  transition: all 0.5s linear;
	}
  }
  
  .tab_wrapper {
	display: inline-block;
	width: 100%;
	position: relative;
  
	* {
	  box-sizing: border-box;
	}
  
	> ul {
	  clear: both;
	  display: inline-block;
	  width: 100%;
	  border-bottom: 1px solid #eff4fc;
	  margin-bottom: 0;
  
	  li {
		float: right;
		cursor: pointer;
		font-weight: 500;
		padding: 8px 18px;
		text-transform: uppercase;
		border: 1px solid #eff4fc;
		font-size: 1.05em;
		border-bottom: 0;
		position: relative;
		border-top: 3px solid #eff4fc;
		margin: 0 5px 0 0;
  
		&.active:after {
		  content: "";
		  position: absolute;
		  bottom: -1px;
		  height: 1px;
		  left: 0;
		  background: $white;
		  width: 100%;
		}
	  }
	}
  
	.controller {
	  display: block;
	  overflow: hidden;
	  clear: both;
	  position: absolute;
	  top: 0;
	  right: 0;
  
	  span {
		padding: 8px 22px;
		color: $white;
		text-transform: uppercase;
		display: inline-block;
  
		&.next {
		  float: right;
		  margin-left: 10px;
		}
	  }
	}
  
	.content_wrapper {
	  float: left;
	  width: 100%;
	  border: 1px solid #eff4fc;
	  border-top: 0;
	  box-sizing: border-box;
  
	  .tab_content {
		display: none;
		padding: 15px 20px;
  
		.error {
		  color: red;
		}
	  }
  
	  .accordian_header {
		display: none;
		padding: 10px 20px;
		text-transform: uppercase;
		font-weight: bold;
		border-top: 1px solid #eee;
		border-bottom: 1px solid $white;
		position: relative;
		cursor: pointer;
  
		&.active {
		  border: 1px solid #eee;
  
		  &:after {
			position: absolute;
			top: 0;
			left: 0;
			content: "";
			width: 5px;
			height: 100%;
		  }
		}
  
		.arrow {
		  display: inline-block;
		  width: 12px;
		  height: 12px;
		  position: relative;
		  border-radius: 2px;
		  transform: rotate(-135deg);
		  background: $white;
		  border-top: 3px solid #ccc;
		  border-left: 3px solid #ccc;
		}
  
		&.active .arrow {
		  transform: rotate(45deg);
		  margin-top: 5px;
		}
	  }
	}
  
	&.left_side {
	  > ul {
		width: 25%;
		float: left;
		border-bottom: 1px solid #ccc;
  
		li {
		  width: 100%;
		  box-sizing: border-box;
		  margin: 0;
		  padding: 12px 18px;
		  border-width: 1px;
		  border-right: 0;
		  border-left: 0;
  
		  &:after {
			left: 0;
			top: 0;
			content: "";
			position: absolute;
			width: 5px;
			height: 100%;
			background: #ccc;
		  }
  
		  &.active {
			border-color: #ccc;
  
			&:after {
			  left: 0;
			  top: 0;
			  width: 5px;
			  height: 100%;
			}
  
			&:before {
			  right: -1px;
			  position: absolute;
			  top: 0;
			  content: "";
			  width: 2px;
			  height: 100%;
			  background: $white;
			}
		  }
		}
	  }
  
	  .content_wrapper {
		width: 75%;
		border: 1px solid #eff4fc;
		float: left;
	  }
  
	  .controller {
		top: -50px;
	  }
	}
  
	&.right_side {
	  > ul {
		width: 25%;
		float: left;
		border-bottom: 1px solid #eff4fc;
  
		li {
		  width: 100%;
		  box-sizing: border-box;
		  margin: 0;
		  padding: 12px 18px;
		  border-width: 1px;
		  border-right: 1px solid #eff4fc;
		  border-left: 1px solid #eff4fc;
		  text-align: left;
  
		  &:after {
			right: 0;
			left: inherit;
			top: 0;
			content: "";
			position: absolute;
			width: 5px;
			height: 100%;
			background: #eff4fc;
		  }
  
		  &.active {
			border-color: #d7e7ff;
  
			&:after {
			  right: 0;
			  left: inherit;
			  top: 0;
			  width: 5px;
			  height: 100%;
			  content: "";
			}
  
			&:before {
			  left: -2px;
			  right: inherit;
			  position: absolute;
			  top: 0;
			  content: "";
			  width: 2px;
			  height: 100%;
			  background: $white;
			}
		  }
		}
	  }
  
	  .content_wrapper {
		width: 75%;
		float: left;
		border: 1px solid #eff4fc;
		border-left: 0;
	  }
  
	  .controller {
		top: -50px;
	  }
	}
  
	&.accordion {
	  .content_wrapper {
		border-color: #eee;
  
		.accordian_header {
		  display: block;
		}
	  }
  
	  > ul {
		display: none;
	  }
	}
  
	.active_tab {
	  display: none;
	  position: relative;
	  background: $black;
	  padding: 12px;
	  text-align: center;
	  padding-right: 50px;
	  color: $white;
	  cursor: pointer;
	  border-radius: 30px;
  
	  .arrow {
		width: 30px;
		height: 30px;
		overflow: hidden;
		line-height: 30px;
		text-align: center;
		background: $white;
		position: absolute;
		right: 6px;
		border-radius: 50%;
		top: 50%;
		transform: translateY(-50%);
  
		&:after {
		  content: "";
		  width: 10px;
		  display: inline-block;
		  height: 10px;
		  border-right: 3px solid $black;
		  border-bottom: 3px solid $black;
		  transform: rotate(45deg);
		  position: absolute;
		  top: 50%;
		  left: 50%;
		  margin-left: -6px;
		  margin-top: -8px;
		}
	  }
  
	  .text {
		text-indent: 39px;
		display: block;
	  }
	}
  }
  
  @media only screen and (max-width: 768px) {
	.tab_wrapper {
	  > ul {
		display: none;
	  }
  
	  .content_wrapper {
		border: 0;
  
		.accordian_header {
		  display: block;
		}
	  }
  
	  &.left_side .content_wrapper, &.right_side .content_wrapper {
		width: 100%;
		border-top: 0;
	  }
  
	  .controller {
		display: none;
	  }
  
	  &.show-as-dropdown {
		> .tab_list {
		  border: 0;
		  box-shadow: 0 0 8px #ccc;
		  position: absolute;
		  z-index: 3;
		  background: $white;
		  border-radius: 5px;
		  margin-top: 15px;
  
		  &:before {
			content: "";
			position: absolute;
			top: -6px;
			left: 50%;
			width: 14px;
			height: 14px;
			background: $white;
			transform: rotate(45deg);
			box-shadow: -3px -4px 5px #efefef;
			margin-left: -7px;
		  }
  
		  li {
			border: 0;
			border-bottom: 1px solid #ccc;
  
			&.active:after {
			  display: none;
			}
		  }
		}
  
		.active_tab {
		  display: block;
		}
  
		> {
		  .tab_list, .content_wrapper > .accordian_header {
			display: none;
		  }
  
		  .tab_list li {
			display: block;
			float: none;
			width: 100%;
		  }
		}
	  }
	}
  }
  
  @media only screen and (min-width: 769px) {
	.tab_wrapper.show-as-dropdown > .tab_list {
	  display: block !important;
	  overflow: hidden;
	}
  }