/* .carousel-item {
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
} */

.carousel-fade .active {
  &.carousel-item-start, &.carousel-item-end {
    transition: all 0.5s ease !important;
  }
}

.slider-wrap {
  position: relative;
  margin: auto;
  height: 6.5rem;
  cursor: pointer;
  transition: all .5s ease;
}

.slider-wrap{
.carousel-indicators {
  margin: 0 !important;
}
}

.thumb {
  margin: 0 0.6rem;

  &.active img {
    border: 2px solid red;
  }
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  height: 6.5rem;
  width: 100%;
  overflow: scroll;
  transition: all .5s ease;
}

.slider-inner {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: fit-content;
  height: 6.5rem;
  transform: translateX(0);
  transition: all 1s ease;
}

.slider {
  .item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 6.5rem;
    top: 1%;
    overflow: hidden;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.slider-wrap{
  .carousel-indicators {
    [data-bs-target] {
      box-sizing: content-box;
      flex: 0 1 auto;
      width: 100px;
      height: auto;
      padding: 0;
      margin-right: 3px;
      margin-left: 3px;
      cursor: pointer;
      background-color: transparent;
      background-clip: padding-box;
      border: 0;
      opacity: .5;
      transition: opacity .6s ease;
    }
  
    .thumb.item.active {
      opacity: 1;
    }
  }
}

.slider-wrap {
  .carousel-control-prev {
    left: 0;
    background: rgb(0, 0, 0);
    z-index: 1;
    height: 3rem;
    top: 30px;
    width: 2rem;
  }

  .carousel-control-next {
    right: 0;
    background: rgb(0, 0, 0);
    z-index: 1;
    height: 3rem;
    top: 30px;
    width: 2rem;
  }
}

.product-slider .slider-wrap {
  margin: 10px 0 0 0;
  padding: 0;
}

.slide-show-image {
  display: flex !important;
  width: 100%;
  height: 28rem !important;

  .carousel-item.active {
    display: flex !important;
    width: 100%;
    height: 28rem !important;
  }
}

#carousel .slide-show-image.carousel-inner {
  overflow: hidden;
  width: 100%;
  height: 28rem !important;
  margin: 0 auto;
}

.product-slider .carousel-item img {
  margin: 0 auto;
  top: 0;
  border-radius: 5px;
}

.slide-items {
  height: 5rem !important;
  margin: 0 5px !important;
  width: 100% !important;
  display: flex;
}

[data-bs-dots="true"] {
  .slider-wrap {
    height: 1.5rem !important;
  }

  .carousel-indicators {
    margin: auto !important;
  }

  .dots {
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    background-color: gray;

    &.active {
      background-color: red;
    }
  }
}

img {
  max-width: 100%;
  vertical-align: middle;
  border-style: none;
}

/*----- Carousel -----*/

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.product-slider{

  .carousel-item {
    position: relative;
    display: none;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    transition: -webkit-transform 0.6s ease;
    transition: transform 0.6s ease;
    transition: transform 0.6s ease, -webkit-transform 0.6s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000px;
    perspective: 1000px;
  }

  .carousel-item {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: transparent;
    }
  
    &.carousel-item-start::before {
      z-index: -1;
    }
  }
  

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
  display: flex;
}

.carousel-item-next, .carousel-item-prev {
  position: absolute;
  height: 30rem;
}
}


.carousel-fade {
  .carousel-item {
    opacity: 0;
    transition-duration: 0.6s;
    transition-property: opacity;
  }

  .carousel-item-next.carousel-item-start, .carousel-item-prev.carousel-item-end, .carousel-item.active {
    opacity: 1;
  }

  .active {
    &.carousel-item-start, &.carousel-item-end {
      opacity: 0;
    }
  }
}

.carousel-control-next, .carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 7%;
  color: $white;
  text-align: center;
  opacity: 0.6;
}

.carousel-control-next {
  &:focus, &:hover {
    color: $white;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
  }
}

.carousel-control-prev {
  &:focus, &:hover {
    color: $white;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
  }
}

.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.slider-wrap{
  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 10px;
    left: 0;
    z-index: 15;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;

    li {
      position: relative;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      width: 7px;
      height: 7px;
      margin-right: 3px;
      margin-left: 3px;
      text-indent: -999px;
      border-radius: 50%;
      background-color: $white-5;
      border: 2px solid $white;
      background: #e2e2e2;

      &::before {
        position: absolute;
        top: -10px;
        left: 0;
        display: inline-block;
        width: 100%;
        height: 10px;
        content: "";
      }

      &::after {
        position: absolute;
        bottom: -10px;
        left: 0;
        display: inline-block;
        width: 100%;
        height: 10px;
        content: "";
      }
    }

    .active {
      background-color: transparent;
    }
  }
}

.carousel-indicators1 {
  &.carousel-indicators {
    position: absolute;
    right: 0;
    top: 10px;
    left: 0;
    z-index: 15;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
  }

  li {
    position: relative;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 7px;
    height: 7px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: $white-5;
    border-radius: 50%;

    &::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: "";
    }

    &::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: "";
    }
  }

  .active {
    background-color: $white;
  }
}

.carousel-indicators2 {
  &.carousel-indicators {
    position: absolute;
    right: 0;
    left: auto;
    top: 0;
    z-index: 15;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-top: 10px;
    list-style: none;
    margin: 13px 22px;
  }

  li {
    position: relative;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 7px;
    height: 7px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: $white-5;
    border-radius: 50%;

    &::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: "";
    }

    &::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: "";
    }
  }

  .active {
    background-color: $white;
  }
}

.carousel-indicators3 {
  &.carousel-indicators {
    position: absolute;
    left: 0;
    right: auto;
    top: 0;
    margin: 13px 22px;
    z-index: 15;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-top: 10px;
    list-style: none;
  }

  li {
    position: relative;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 7px;
    height: 7px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: $white-5;
    border-radius: 50%;

    &::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: "";
    }

    &::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: "";
    }
  }

  .active {
    background-color: $white;
  }
}

.carousel-indicators4 {
  &.carousel-indicators {
    position: absolute;
    right: 0;
    left: auto;
    margin: 22px 22px;
    bottom: 0;
    z-index: 15;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-top: 10%;
    list-style: none;
  }

  li {
    position: relative;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 7px;
    height: 7px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: $white-5;
    border-radius: 50%;

    &::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: "";
    }

    &::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: "";
    }
  }

  .active {
    background-color: $white;
  }
}

.carousel-indicators5 {
  &.carousel-indicators {
    position: absolute;
    bottom: 0;
    left: 0;
    right: auto;
    margin: 22px 22px;
    z-index: 15;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    list-style: none;
  }

  li {
    position: relative;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 7px;
    height: 7px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: $white-5;
    border-radius: 50%;

    &::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: "";
    }

    &::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: "";
    }
  }

  .active {
    background-color: $white;
  }
}

.carousel-caption {
  position: absolute;
  right: 15%;
  top: 30%;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: $white;
  text-align: center;
}

.carousel-item-background {
  content: "";
  background: $black-5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.carousel-bg {
  .prev-icon, .next-icon {
    background-color: $black-7;
    padding: 5px 15px;
    border-radius: 100px;
    font-size: 20px;
  }
}

.carousel-control {
  &:active, &:focus, &:hover {
    color: #333;
  }
}

.thumbcarousel {
  .carousel-control-prev {
    left: 0;
    background: $black-5;
  }

  .carousel-control-next {
    right: 0;
    background: $black-5;
  }
}

.slider-wrap{
.carousel .carousel-indicators {
  bottom: -1rem;
}

.carousel-indicators li {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin: 1px 2px;

  &.active {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin: 1px 2px;
  }
}

.carousel .carousel-control {
  height: 40px;
  width: 40px;
  background: 0 0;
  margin: auto 0;
  border-radius: 50%;
  background: $black-6;
  z-index: 999;

  i {
    font-size: 32px;
    position: absolute;
    top: 1.6rem;
    display: inline-block;
    margin: -18px 0 0 0;
    z-index: 5;
    left: 0;
    right: 0;
    color: $white-9;
    text-shadow: none;
    font-weight: 700;
    z-index: 999;
  }
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev {
  left: 0;
}

#carousel-controls.owl-carousel .owl-item img {
  width: 100%;
}
}

.slider-wrap {
  .thumb.item img {
    height: 4.5rem;
    border-radius: 3px;
  }

  .carousel-control-next {
    top: 30px !important;
    height: 35px !important;
    right: 9px;
    font-size: 5px !important;
    border: 1px solid #140202f2;
    width: 35px;
    color: $white!important;
    background-color: #140202f2;
  }

  .carousel-control-prev {
    top: 30px !important;
    height: 35px !important;
    left: 9px !important;
    font-size: 5px !important;
    border: 1px solid #140202f2;
    width: 35px;
    color: $white!important;
    background-color: #140202f2;

    &.btn-disabled {
      display: none;
    }
  }

  .carousel-control-next.btn-disabled {
    display: none;
  }
}

.slide-show-image {
  display: flex !important;
  width: 100%;
  height: 28rem !important;

  .carousel-item.active {
    display: flex !important;
    width: 100%;
    height: 28rem !important;
  }
}

#carousel .slide-show-image.carousel-inner {
  overflow: hidden;
  width: 100%;
  height: 28rem !important;
  margin: 0 auto;
}

.product-slider .carousel-item img {
  margin: 0 auto;
  top: 0;
}

.slide-show-image {
  .carousel-item-start, .carousel-item-end {
    margin: 0 auto;
    height: 28rem !important;
  }
}

.slide-items {
  .carousel-item-start, .carousel-item-end {
    margin: 0 auto;
    height: 5rem !important;
  }

  height: 5rem !important;
  margin: 0 5px !important;
  width: 100% !important;
  display: flex;
}

.slider-wrap {
  .carousel-control-next i, .carousel-control-prev i {
    font-size: 1.5rem;
  }
}

@media (max-width: 1279px) and (min-width: 768px) {
  /* .slide-show-image
  display: flex !important;
  width: 100%;
  height: 15rem !important;
  }
  .slide-show-image .carousel-item.active{
    display: flex !important;
    width: 100%;
    height: 15rem !important;
  }
  .slide-show-image  .carousel-item-left{
    margin: 0 auto;
    height: 15rem !important;
  }
  .slide-show-image .carousel-item-right{
    margin: 0 auto;
    height: 15rem !important;
  }
  .slide-items {
    height: 3rem !important;
  }
  .product-slider .carousel-item .thumb {
    margin: 0 3px !important;
  } */

  .slide-show-image .carousel-item.active {
    height: 21rem !important;
    margin: 0 auto;
  }

  #carousel .slide-show-image.carousel-inner {
    overflow: hidden;
    width: 100%;
    height: 21rem !important;
    margin: 0 auto;
  }

  .slide-show-image {
    .carousel-item-start, .carousel-item-end {
      margin: 0 auto;
      height: 21rem !important;
    }
  }

  /* .slider-wrap  .carousel-control-next{
    top: 36px !important;
    height: 25px !important;
    right: -29px !important;
    width: 25px;
    color: $white;
  }
  .slider-wrap  .carousel-control-prev{
    top: 36px !important;
    height: 25px !important;
    left: -29px !important;
    width: 25px;
    color: $white;
  } */

  .slider-wrap {
    .carousel-control-next i, .carousel-control-prev i {
      font-size: 1.5rem;
    }
  }
}

@media (max-width: 767px) {
  .slide-show-image .carousel-item.active {
    height: 13rem !important;
    margin: 0 auto;
  }

  #carousel .slide-show-image.carousel-inner {
    overflow: hidden;
    width: 100%;
    height: 13rem !important;
    margin: 0 auto;
  }

  .slide-show-image {
    .carousel-item-start, .carousel-item-end {
      margin: 0 auto;
      height: 13rem !important;
    }
  }
}

/* .carousel-item {
  position: relative;
} */

