@import "custom/fonts/fonts";
@import "variables";


/* ###### plugins ####### */

@import "plugins/accordion/accordion";
@import "plugins/boot-slider/boots-slider";
@import "plugins/bootstrap-daterangepicker/daterangepicker";
@import "plugins/fancyuploder/fancy_fileupload";
@import "plugins/jquery-countdown/countdown";
@import "plugins/jquery-uislider/jquery-ui";
@import "plugins/owl-carousel/owl.carousel";
@import "plugins/prettycheckbox/pretty-checkbox.min";
@import "plugins/pscrollbar/pscrollbar";
@import "plugins/ratings-2/star-rating-svg";
@import "plugins/select2/select2.min";
@import "plugins/tabs/style";
@import "plugins/timeline/timeline";
@import "plugins/video/insideElementDemo";